import { template as template_e586fc8a945b43beab456482b2075e1f } from "@ember/template-compiler";
const SidebarSectionMessage = template_e586fc8a945b43beab456482b2075e1f(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
