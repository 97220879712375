import { template as template_54aacb8b7db14fd294f9aeb4f047edb1 } from "@ember/template-compiler";
const FKLabel = template_54aacb8b7db14fd294f9aeb4f047edb1(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
