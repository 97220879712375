import { template as template_48488608597f48f698d1a259351ea19e } from "@ember/template-compiler";
const FKControlMenuContainer = template_48488608597f48f698d1a259351ea19e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
